import { makeRoot } from '~/utils/url'

export default function ({ $config, $country, $ph }, inject) {
  inject('url', {
    makeRoot (url) {
      return makeRoot(url)
    },
    home () {
      return '/' + $country.current.code
    },
    app () {
      return $config.appUrl
    },
    internalApi () {
      return $config.internalApiUrl
    },
    signUp (query = {}) {
      const url = new URL('/signup', $config.appUrl)

      Object.keys(query).forEach((key) => {
        url.searchParams.append(key, query[key])
      })

      if (process.client && $ph.get_property('$user_state') === 'anonymous') {
        url.searchParams.append('ph_did', $ph.get_distinct_id())
        url.searchParams.append('ph_sid', $ph.get_session_id())
      }

      return url.toString()
    },
    demo () {
      switch ($country.current.code) {
        case $country.FINLAND:
          return '/fi-fi/demo'
        case $country.UK:
          return '/en-gb/demo'
        default:
          throw new Error('Invalid country for demo url')
      }
    },
    contactUs () {
      switch ($country.current.code) {
        case $country.FINLAND:
          return '/fi-fi/yhteystiedot'
        case $country.SWEDEN:
          return '/sv-se/kontaktuppgifter'
        case $country.UK:
          return '/en-gb/contact-us'
        case $country.GERMANY:
          return '/de-de/kontakt'
        case $country.POLAND:
          return '/pl-pl/kontakt'
        default:
          throw new Error('Invalid country')
      }
    },
    signUpVerify (query = {}) {
      const url = new URL('/signup/verify', $config.appUrl)

      Object.keys(query).forEach((key) => {
        url.searchParams.append(key, query[key])
      })

      return url.toString()
    },
    login (email) {
      const url = new URL('/login', $config.appUrl)
      if (email) {
        url.searchParams.append('email', email)
      }
      return url.toString()
    },
    terms () {
      return '/en/terms'
    },
    blogListing () {
      switch ($country.current.code) {
        case $country.FINLAND:
          return '/fi-fi/uutiset'
        case $country.SWEDEN:
          return '/sv-se/nyheter'
        case $country.UK:
          return '/en-gb/news'
        case $country.GERMANY:
          return '/de-de/news'
        case $country.POLAND:
          return '/pl-pl/aktualnosci'
        default:
          throw new Error('Invalid country')
      }
    },
    blogArticle (slug) {
      switch ($country.current.code) {
        case $country.FINLAND:
          return `/fi-fi/uutiset/${slug}`
        case $country.SWEDEN:
          return `/sv-se/nyheter/${slug}`
        case $country.UK:
          return `/en-gb/news/${slug}`
        case $country.GERMANY:
          return `/de-de/news/${slug}`
        case $country.POLAND:
          return `/pl-pl/aktualnosci/${slug}`
        default:
          throw new Error('Invalid country')
      }
    },
    privacyPolicy () {
      return '/en/privacy'
    },
    templateListing () {
      switch ($country.current.code) {
        case $country.FINLAND:
          return '/fi-fi/asiakirjamallit'
        case $country.SWEDEN:
          return '/sv-se/dokumentmallar'
        case $country.UK:
          return '/en-gb/legal-templates'
        case $country.GERMANY:
          return '/de-de/rechtsdokumente'
        case $country.POLAND:
          return '/pl-pl/szablony'
        default:
          throw new Error('Invalid country')
      }
    },
    allTemplates () {
      switch ($country.current.code) {
        case $country.FINLAND:
          return '/fi-fi/asiakirjamallit'
        case $country.SWEDEN:
          return '/sv-se/dokumentmallar'
        case $country.UK:
          return '/en-gb/legal-templates'
        case $country.GERMANY:
          return '/de-de/rechtsdokumente'
        case $country.POLAND:
          return '/pl-pl/szablony'
        default:
          throw new Error('Invalid country')
      }
    },
    template (id, slug) {
      switch ($country.current.code) {
        case $country.FINLAND:
          return `/fi-fi/asiakirjamallit/${slug}/${id}`
        case $country.SWEDEN:
          return `/sv-se/dokumentmallar/${slug}/${id}`
        case $country.UK:
          return `/en-gb/legal-templates/${slug}/${id}`
        case $country.GERMANY:
          return `/de-de/rechtsdokumente/${slug}/${id}`
        case $country.POLAND:
          return `/pl-pl/szablony/${slug}/${id}`
        default:
          throw new Error('Invalid country')
      }
    },
    templateCategory (id, slug) {
      switch ($country.current.code) {
        case $country.FINLAND:
          return `/fi-fi/asiakirjamallit/kategoriat/${slug}/${id}`
        case $country.SWEDEN:
          return `/sv-se/dokumentmallar/kategorier/${slug}/${id}`
        case $country.UK:
          return `/en-gb/legal-templates/categories/${slug}/${id}`
        case $country.GERMANY:
          return `/de-de/rechtsdokumente/kategorien/${slug}/${id}`
        case $country.POLAND:
          return `/pl-pl/szablony/kategorie/${slug}/${id}`
        default:
          throw new Error('Invalid country')
      }
    },
    article (slug) {
      switch ($country.current.code) {
        case $country.FINLAND:
          return `/fi-fi/lakitieto/${slug}`
        case $country.SWEDEN:
          return `/sv-se/juridisk-kunskap/${slug}`
        case $country.UK:
          return `/en-gb/legal-hub/${slug}`
        case $country.GERMANY:
          return `/de-de/rechtswissen/${slug}`
        case $country.POLAND:
          return `/pl-pl/baza-prawna/${slug}`
        default:
          throw new Error('Invalid country')
      }
    },
    articleCategory (slug) {
      switch ($country.current.code) {
        case $country.FINLAND:
          return `/fi-fi/lakitieto/aihe/${slug}`
        case $country.SWEDEN:
          return `/sv-se/juridisk-kunskap/tema/${slug}`
        case $country.UK:
          return `/en-gb/legal-hub/topic/${slug}`
        case $country.GERMANY:
          return `/de-de/rechtswissen/kategorie/${slug}`
        case $country.POLAND:
          return `/pl-pl/baza-prawna/kategorie/${slug}`
        default:
          throw new Error('Invalid country')
      }
    },
    articlesIndex () {
      switch ($country.current.code) {
        case $country.FINLAND:
          return '/fi-fi/lakitieto'
        case $country.SWEDEN:
          return '/sv-se/juridisk-kunskap'
        case $country.UK:
          return '/en-gb/legal-hub'
        case $country.GERMANY:
          return '/de-de/rechtswissen'
        case $country.POLAND:
          return '/pl-pl/baza-prawna'
        default:
          throw new Error('Invalid country')
      }
    },
  })
}
